import React from 'react';
import _lowerCase from 'lodash/lowerCase';
import _upperCase from 'lodash/upperCase';
import _upperFirst from 'lodash/upperFirst';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';

const UserInfo: React.FC = () => {
  const userData = useAppSelector(userSelectors.getUserData)!;
  const initials = userData.fullName
    .split(' ')
    .reduce(
      (acc: string, name: string) =>
        `${acc}${_upperCase(name.substring(0, 1))}`,
      '',
    );
  return (
    <Box mt="auto" px={2}>
      <Stack direction="row" alignItems="center">
        <Avatar>{initials ?? ''}</Avatar>
        <Stack ml={2}>
          <Typography variant="body1">{userData.fullName}</Typography>
          <Typography variant="body2">
            {_upperFirst(_lowerCase(userData.role))}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default UserInfo;
