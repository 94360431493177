export const USERS_UPLOAD_HELP_DATA = [
  {
    name: 'OKPO',
    description: 'IНН пользователя',
    example: '99999999',
    required: true,
  },
  {
    name: 'FULL_NAME',
    description: 'Фамилия и имя пользователя',
    example: 'test_user',
    required: true,
  },
  {
    name: 'USERNAME',
    description: 'Логин пользователя в системe PUMB',
    example: 'test_user',
    required: true,
  },
  {
    name: 'PASSWORD',
    description: 'Пароль пользователя в системе PUMB',
    example: 'test_password',
    required: true,
  },
];
