import React, { useState } from 'react';
import formatDate from 'date-fns/format';
import {
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { BUSINESS_TRANSACTION_TYPES, FILE_FORMAT } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux';
import { refetchBusinessTransactions } from '../../redux/businessTransactions';
import { generateUnsignedStatements } from '../../redux/unsignedStatements';
import BusinessTransactions from '../businessTransactions/BusinessTransactions';
import PageWrapper from '../../components/pageWrapper/PageWrapper';

const UnsignedStatements: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state) => state.unsignedStatements.isLoading,
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [fileFormat, setFileFormat] = useState<string | null>(null);
  const isGenerateDisabled = !startDate || !endDate || !fileFormat;

  const handleFileFormatChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFileFormat((event.target as HTMLInputElement).value);
  };

  const handleGenerate = async () => {
    const body = {
      startDate: formatDate(startDate as Date, 'dd.MM.yyyy'),
      endDate: formatDate(endDate as Date, 'dd.MM.yyyy'),
      fileFormat,
    };
    await dispatch(generateUnsignedStatements(body));
    await dispatch(refetchBusinessTransactions());
    setStartDate(null);
    setEndDate(null);
    setFileFormat(null);
  };

  return (
    <PageWrapper>
      <Typography variant="h5" gutterBottom>
        Generate unsigned statements
      </Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={2}>
            <DatePicker
              label="From"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="To"
              value={endDate}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <RadioGroup
              value={fileFormat}
              onChange={handleFileFormatChange}
              sx={{ pl: 2 }}
              row
            >
              <FormControlLabel
                value={FILE_FORMAT.CSV}
                control={<Radio />}
                label={FILE_FORMAT.CSV}
              />
              <FormControlLabel
                value={FILE_FORMAT.PDF}
                control={<Radio />}
                label={FILE_FORMAT.PDF}
              />
            </RadioGroup>
            <LoadingButton
              variant="contained"
              disabled={isGenerateDisabled}
              loading={isLoading}
              onClick={handleGenerate}
            >
              Generate
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
      <BusinessTransactions
        type={BUSINESS_TRANSACTION_TYPES.CreateUnsignedFinStatement}
      />
    </PageWrapper>
  );
};

export default UnsignedStatements;
