import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../redux';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import {
  Operation,
  upload,
  getFolderByLink,
  clearExistingFolderLink,
} from '../../redux/automator';
import { parseCsvToJson } from '../../utils';
import { notificationAlert } from '../../redux/notifications';
import { Controller, useForm } from 'react-hook-form';
import { handleZipFolder, containsSequence } from './AccountingAutomator.utils';
import { defaultCsvFieldMap } from './AccountingAutomator.consts';
import AccountingAutomatorHelp from './AccountingAutomatorHelp';
import BusinessTransactions from '../businessTransactions/BusinessTransactions';
import { BUSINESS_TRANSACTION_TYPES } from '../../constants';
import { refetchBusinessTransactions } from '../../redux/businessTransactions';

interface ILevel {
  selectedField: keyof typeof defaultCsvFieldMap | '';
  operation: Operation;
}

interface IFormValues {
  searchBy: (keyof typeof defaultCsvFieldMap)[];
  renameTemplate: string;
  driveLink: string;
  level1: ILevel;
  level2: ILevel;
  level3: ILevel;
  level4: ILevel;
}

const AccountingAutomator: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>();
  const [formData, setFormData] = useState<IFormValues>({
    searchBy: [],
    renameTemplate: '',
    driveLink: '',
    level1: { selectedField: '', operation: Operation.find },
    level2: { selectedField: '', operation: Operation.empty },
    level3: { selectedField: '', operation: Operation.empty },
    level4: { selectedField: '', operation: Operation.empty },
  });

  const isLoading = useAppSelector((state) => state.automator.isLoading);
  const existingFolderForUpload = useAppSelector(
    (state) => state.automator.existingGoogleFolder,
  );
  const [selectedDirectory, setSelectedDirectory] = useState<Blob | null>(null);
  const [selectedDirectoryName, setSelectedDirectoryName] = useState<
    string | null
  >(null);
  const [translateMap, setTranslateMap] =
    useState<Record<string, string>>(defaultCsvFieldMap);

  const file1InputRef = React.useRef<HTMLInputElement | null>(null);
  const file2InputRef = React.useRef<HTMLInputElement | null>(null);
  const [registerFile, setRegisterFile] = React.useState<Blob | null>(null);
  const [dataFile, setDataFile] = React.useState<Blob | null>(null);
  const [mode, setMode] = React.useState<'folder' | 'file'>('file');

  const checkFolderExistence = useCallback(
    debounce((driveLink) => {
      dispatch(clearExistingFolderLink());
      dispatch(getFolderByLink(driveLink));
    }, 1000),
    [],
  );

  useEffect(() => {
    if (formData.driveLink) {
      checkFolderExistence(formData.driveLink);
    }
  }, [formData.driveLink, checkFolderExistence]);

  const handleFormValueChange = (
    field: keyof IFormValues,
    value: string | ILevel | (keyof typeof defaultCsvFieldMap)[],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleRegisterFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = e.target as HTMLInputElement;

    if (!target.files || !target.files.length || !e.target.files?.[0]) {
      return;
    }

    const file = e.target.files?.[0] || null;

    const parsedCsv = await parseCsvToJson(file, { trimHeaders: true });

    const headers = Object.keys(parsedCsv[0]);
    const missingHeaders = Object.values(defaultCsvFieldMap).filter(
      (val) => !headers.includes(val),
    );

    if (missingHeaders.length > 0) {
      dispatch(
        notificationAlert(
          `Headers in Register CSV are not valid. Expected headers: ${Object.values(
            defaultCsvFieldMap,
          )}. Missing headers: ${missingHeaders}`,
          { persist: true, variant: 'error' },
        ),
      );

      setRegisterFile(null);
      (file1InputRef.current as HTMLInputElement).files = null;
      (file1InputRef.current as HTMLInputElement).value = '';
      return;
    }

    if (containsSequence(parsedCsv, '???')) {
      dispatch(
        notificationAlert(
          `WARN: some values in registry contain '???'. Check for errors in your data`,
          { persist: true, variant: 'warning' },
        ),
      );
    }
    const nonEmptyFields = headers.filter((key) =>
      parsedCsv.slice(1).some((obj) => obj[key]),
    );

    const newMap = Object.fromEntries(
      Object.entries(defaultCsvFieldMap).filter(([, value]) =>
        nonEmptyFields.includes(value),
      ),
    );

    setTranslateMap(newMap);
    setRegisterFile(file);
  };

  const handleDataFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files?.length) {
      const file = e.target.files?.[0] || null;
      setDataFile(file);
    } else {
      setDataFile(null);
    }
  };

  const handleDirectorySelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }

    setDataFile(null);
    const res = await handleZipFolder(Array.from(event.target.files));

    setSelectedDirectoryName(
      event.target.files[0].webkitRelativePath.split('/')[0],
    );
    setSelectedDirectory(res);
  };

  const hasMissingData = () =>
    (!dataFile && !selectedDirectory) ||
    !existingFolderForUpload ||
    !registerFile;

  const onSubmit = async () => {
    const uploadForm = new FormData();

    uploadForm.append('registerFile', registerFile as Blob);

    if (mode === 'file') {
      uploadForm.append('dataFile', dataFile as Blob);
    } else if (mode === 'folder') {
      uploadForm.append(
        'dataFile',
        selectedDirectory as Blob,
        `${selectedDirectoryName}.zip`,
      );
    }

    uploadForm.append('searchCriteria', JSON.stringify(formData.searchBy));
    uploadForm.append('renameTemplate', formData.renameTemplate);
    uploadForm.append('folderForStorage', formData.driveLink);

    [
      formData.level1,
      formData.level2,
      formData.level3,
      formData.level4,
    ].forEach((level, index) => {
      if (level.operation !== Operation.empty) {
        uploadForm.append(
          `level${index + 1}`,
          JSON.stringify({
            operation: level.operation,
            name: level.selectedField,
          }),
        );
      }
    });

    await dispatch(upload(uploadForm));
    await dispatch(refetchBusinessTransactions());
  };

  const handleChipClick = (data: string) => {
    handleFormValueChange(
      'renameTemplate',
      `${formData.renameTemplate ?? ''}{${data}}`,
    );
  };

  return (
    <PageWrapper>
      <Typography variant="h5" gutterBottom>
        Accounting Automator
      </Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="center" spacing={2} sx={{ maxWidth: 450 }}>
              <TextField
                inputRef={file1InputRef}
                id="input-file"
                label="Upload Register"
                InputLabelProps={{
                  shrink: true,
                }}
                type="file"
                onChange={handleRegisterFileChange}
                variant="outlined"
                sx={{ minWidth: 450 }}
              />

              <Stack>
                <RadioGroup
                  row
                  defaultValue={mode}
                  onChange={(newValue) => {
                    setMode(newValue.target.value as 'file' | 'folder');
                  }}
                >
                  <FormControlLabel
                    value="file"
                    control={<Radio />}
                    label="File"
                  />
                  <FormControlLabel
                    value="folder"
                    control={<Radio />}
                    label="Directory"
                  />
                </RadioGroup>

                {mode === 'file' && (
                  <TextField
                    inputRef={file2InputRef}
                    id="input-file"
                    label="Data Archive"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="file"
                    onChange={handleDataFileChange}
                    variant="outlined"
                    sx={{ minWidth: 450 }}
                  />
                )}

                {mode === 'folder' && (
                  <Stack>
                    <Typography variant="caption" gutterBottom>
                      Data directory
                    </Typography>

                    {selectedDirectoryName && (
                      <Typography>
                        Selected folder: {selectedDirectoryName}
                      </Typography>
                    )}
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ minWidth: 450 }}
                    >
                      Browse
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleDirectorySelect}
                        // https://stackoverflow.com/questions/71444475/webkitdirectory-in-typescript-and-react
                        /* @ts-expect-error test */
                        webkitdirectory=""
                        directory=""
                      />
                    </Button>
                  </Stack>
                )}

                {/* TODO: move to env var  */}
                <Typography variant="caption" gutterBottom>
                  Max file size: 18mb
                </Typography>
              </Stack>
            </Stack>

            <Stack spacing={2} sx={{ maxWidth: 450 }}>
              <Controller
                name="renameTemplate"
                control={control}
                defaultValue=""
                rules={{ required: !formData.renameTemplate }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ maxWidth: 450, mt: 3 }}
                    label="Rename template"
                    placeholder="Rename template"
                    variant="outlined"
                    value={formData.renameTemplate}
                    InputLabelProps={{ shrink: !!formData.renameTemplate }}
                    onChange={(e) =>
                      handleFormValueChange('renameTemplate', e.target.value)
                    }
                    error={!!errors?.renameTemplate}
                    helperText={
                      errors?.renameTemplate ? 'Field is required' : ''
                    }
                  />
                )}
              />

              <Stack
                direction="row"
                sx={{ maxWidth: 450, flexWrap: 'wrap', gap: 1 }}
              >
                <>
                  {Object.entries(translateMap).map(([key, value]) => {
                    return (
                      <Chip
                        key={key}
                        label={value}
                        onClick={() => handleChipClick(value)}
                      />
                    );
                  })}
                </>
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <Controller
                name="searchBy"
                control={control}
                defaultValue={[]}
                rules={{ required: !formData.searchBy.length }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Search by Multiple Fields"
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => {
                        const selectedValues = selected as string[];

                        return (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selectedValues.map((value, index) => (
                              <Box
                                key={value}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Chip label={translateMap[value]} />
                                {index < selectedValues.length - 1 && (
                                  <Box sx={{ mx: 1 }}>→</Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        );
                      },
                    }}
                    value={formData.searchBy}
                    sx={{ maxWidth: 450, mt: 3 }}
                    onChange={(e) =>
                      handleFormValueChange('searchBy', e.target.value)
                    }
                    error={!!errors?.searchBy}
                    helperText={errors?.searchBy ? 'Field is required' : ''}
                  >
                    {Object.entries(translateMap).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Controller
                name="driveLink"
                control={control}
                defaultValue=""
                rules={{ required: !formData.driveLink }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ maxWidth: 450 }}
                    label="Google drive folder link"
                    placeholder="https://drive.google.com/drive/u/0/folders/123"
                    variant="outlined"
                    onChange={(e) =>
                      handleFormValueChange('driveLink', e.target.value)
                    }
                    value={formData.driveLink}
                    error={!!errors?.driveLink}
                    helperText={errors?.driveLink ? 'Field is required' : ''}
                  />
                )}
              />

              <Typography
                sx={{
                  color: existingFolderForUpload?.name ? 'green' : 'inherit',
                }}
              >
                Folder for storage:{' '}
                {existingFolderForUpload?.name || 'Not chosen'}
              </Typography>
            </Stack>

            {['level1', 'level2', 'level3', 'level4'].map((level) => {
              const levelName = level as
                | 'level1'
                | 'level2'
                | 'level3'
                | 'level4';

              return (
                <Stack
                  key={levelName}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Controller
                    name={`${levelName}.selectedField`}
                    control={control}
                    defaultValue=""
                    rules={{
                      required:
                        formData[levelName].operation !== Operation.empty &&
                        !formData[levelName].selectedField,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={`Level ${level.slice(-1)}`}
                        variant="outlined"
                        sx={{ minWidth: 450, mt: 3 }}
                        onChange={(e) =>
                          handleFormValueChange(levelName, {
                            operation: formData[levelName].operation,
                            selectedField: e.target
                              .value as keyof typeof defaultCsvFieldMap,
                          })
                        }
                        disabled={
                          formData[levelName].operation === Operation.empty
                        }
                        value={formData[levelName].selectedField}
                        error={!!errors[levelName]?.selectedField}
                        helperText={
                          errors[levelName]?.selectedField
                            ? 'Field is required'
                            : ''
                        }
                      >
                        {Object.entries(translateMap).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    name={`${levelName}.operation`}
                    control={control}
                    defaultValue={Operation.empty}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        sx={{ pl: 2 }}
                        row
                        onChange={(e) =>
                          handleFormValueChange(levelName, {
                            selectedField: formData[levelName].selectedField,
                            operation: e.target.value as Operation,
                          })
                        }
                        value={formData[levelName].operation}
                      >
                        <FormControlLabel
                          value="empty"
                          control={<Radio />}
                          label="Empty"
                        />
                        <FormControlLabel
                          value="find"
                          control={<Radio />}
                          label="Find existing"
                        />
                        <FormControlLabel
                          value="create"
                          control={<Radio />}
                          label="Create"
                        />
                      </RadioGroup>
                    )}
                  />
                </Stack>
              );
            })}

            <LoadingButton
              variant="contained"
              loading={isLoading}
              type="submit"
              disabled={hasMissingData()}
            >
              Upload
            </LoadingButton>
          </form>
        </CardContent>

        <BusinessTransactions
          type={BUSINESS_TRANSACTION_TYPES.UploadAccountingAutomatorDocument}
        />

        <AccountingAutomatorHelp />
      </Card>
    </PageWrapper>
  );
};

export default AccountingAutomator;
