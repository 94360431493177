import { createSlice } from '@reduxjs/toolkit';
import { taxUploadStoreKey } from './taxUpload.const';
import { uploadTaxes } from './taxUpload.thunks';

interface ISliceState {
  isLoading: boolean;
}

const initialState: ISliceState = {
  isLoading: false,
};

export const taxUploadSlice = createSlice({
  name: taxUploadStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadTaxes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadTaxes.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadTaxes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
