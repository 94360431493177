import React from 'react';
import List from '@mui/material/List';
import NavigationLink from './NavigationLink';
import { routesConfig } from '../../routes/routesConfig';
import { useAppSelector } from '../../redux';
import { userSelectors } from '../../redux/user';

const Navigation: React.FC = () => {
  const userData = useAppSelector(userSelectors.getUserData);

  const renderLinks = () => {
    if (!userData) {
      return;
    }
    return routesConfig.flatMap((configElement) => {
      const { route, Icon, label, allowedWhen } = configElement;

      if (
        allowedWhen.some((feature) =>
          userData.allowedFeatures.includes(feature),
        )
      ) {
        return (
          <NavigationLink
            key={route}
            icon={<Icon />}
            to={route}
            title={label}
          />
        );
      }

      return null;
    });
  };

  return <List sx={{ pt: 2 }}>{renderLinks()}</List>;
};

export default Navigation;
