import Papa from 'papaparse';
import * as XLSX from 'xlsx';

export const calculateProgressPercentage = (
  countSuccessful: number,
  countAll: number,
) => {
  if (!countSuccessful || !countAll) {
    return 0;
  }
  return Math.round((countSuccessful / countAll) * 100);
};

export const downloadXlsxOrCsv = (
  data: Array<{ [key: string]: any }>,
  fileType: 'xlsx' | 'csv',
  fileName: string,
  fields?: Set<string>,
) => {
  const processedData = data.map((item) => {
    const processedItem: Record<string, string> = {};
    for (const key in item) {
      if (fields?.has(key)) {
        processedItem[key] = JSON.stringify((item as any)[key]);
      }
    }
    return processedItem;
  });

  if (fileType === 'xlsx') {
    const worksheet = XLSX.utils.json_to_sheet(processedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Worksheet');
    XLSX.writeFile(workbook, fileName);
  } else {
    const csv = Papa.unparse(processedData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};
