import React from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface IProps {
  icon: React.ReactNode;
  title: string;
  to: string;
}

const NavigationLink: React.FC<IProps> = ({ icon, title, to }) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItemButton
      selected={Boolean(match)}
      onClick={() => {
        navigate(to, { replace: true });
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default NavigationLink;
