import jschardet from 'jschardet';
import Papa from 'papaparse';

const detectCsvEncoding = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e?.target?.result;
      if (typeof result === 'string') {
        const encoding = jschardet.detect(result).encoding;
        resolve(encoding);
      }
    };
    reader.readAsBinaryString(file);
  });
};

export const parseCsvToJson = (
  file: File,
  options?: { trimHeaders?: boolean },
) => {
  return new Promise<any[]>(async (resolve) => {
    const encoding = await detectCsvEncoding(file);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: false,
      encoding: encoding,
      transformHeader: options?.trimHeaders
        ? (header) => header.trim()
        : undefined,
      complete: (result) => {
        resolve(result.data);
      },
    });
  });
};

export default parseCsvToJson;
