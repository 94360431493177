import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { IBusinessTransaction } from '../../types';
import { businessTransactionsStoreKey } from './businessTransactions.const';
import {
  fetchAllBusinessTransactions,
  fetchBusinessTransactionById,
} from './businessTransactions.thunks';

export interface IBusinessTransactionsSliceState {
  apiStatus: API_STATUS;
  transactionIdApiStatus: API_STATUS;
  data: IBusinessTransaction[];
  refetch: boolean;
}

const initialState: IBusinessTransactionsSliceState = {
  apiStatus: API_STATUS.IDLE,
  transactionIdApiStatus: API_STATUS.IDLE,
  data: [],
  refetch: false,
};

export const businessTransactionsSlice = createSlice({
  name: businessTransactionsStoreKey,
  initialState,
  reducers: {
    clearBusinessTransactions: () => initialState,
    refetchBusinessTransactions: (state) => {
      state.refetch = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBusinessTransactions.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
        state.refetch = false;
      })
      .addCase(
        fetchAllBusinessTransactions.fulfilled,
        (state, action: PayloadAction<IBusinessTransaction[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.data = action.payload;
          state.refetch = false;
        },
      )
      .addCase(fetchAllBusinessTransactions.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.refetch = false;
      })
      .addCase(fetchBusinessTransactionById.pending, (state) => {
        state.transactionIdApiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchBusinessTransactionById.fulfilled,
        (state, action: PayloadAction<IBusinessTransaction>) => {
          state.transactionIdApiStatus = API_STATUS.IDLE;
          state.data = state.data.map((bt) => {
            if (bt.id === action.payload.id) {
              return action.payload;
            }
            return bt;
          });
        },
      )
      .addCase(fetchBusinessTransactionById.rejected, (state) => {
        state.transactionIdApiStatus = API_STATUS.FAILED;
      });
  },
});

export const { clearBusinessTransactions, refetchBusinessTransactions } =
  businessTransactionsSlice.actions;
