import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { IUserData } from '../../types';
import { userStoreKey } from './user.const';
import { fetchUser } from './user.thunks';

export interface IUserSliceState {
  apiStatus: API_STATUS;
  userData: IUserData | null;
}

const initialState: IUserSliceState = {
  apiStatus: API_STATUS.IDLE,
  userData: null,
};

export const userSlice = createSlice({
  name: userStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchUser.fulfilled,
        (state, action: PayloadAction<IUserData>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.userData = action.payload;
        },
      )
      .addCase(fetchUser.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});
