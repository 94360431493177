import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { IBusinessTransactionQueryObj } from './businessTransactions.types';
import { businessTransactionsStoreKey } from './businessTransactions.const';

export const fetchAllBusinessTransactions = createAsyncThunk(
  `${businessTransactionsStoreKey}/fetchAllBusinessTransactions`,
  async (queryObj: IBusinessTransactionQueryObj) => {
    try {
      const query = new URLSearchParams(queryObj).toString();
      const response = await axios.get(
        apiEndpoints.fetchAllBusinessTransactions(query),
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const fetchBusinessTransactionById = createAsyncThunk(
  `${businessTransactionsStoreKey}/fetchBusinessTransactionById`,
  async (id: string) => {
    try {
      const response = await axios.get(
        apiEndpoints.fetchBusinessTransactionById(id),
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
