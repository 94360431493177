export enum AllowedFeatures {
  ALL = 'ALL',
  ACCOUNTING_AUTOMATOR = 'ACCOUNTING_AUTOMATOR',
  AUTH = 'AUTH',
}

export interface IUserData {
  id: string;
  email: string;
  fullName: string;
  role: string;
  allowedFeatures: AllowedFeatures[];
}
