import React from 'react';
import TextField from '@mui/material/TextField';

type CsvInputRef = HTMLInputElement | null;

interface IInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CsvInput = React.forwardRef<CsvInputRef, IInputProps>(
  ({ onChange }, ref) => {
    return (
      <TextField
        inputRef={ref}
        id="input-file"
        label="Import Csv"
        inputProps={{
          accept: '.csv',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        type="file"
        onChange={onChange}
        variant="outlined"
        sx={{ minWidth: 450 }}
      />
    );
  },
);

export default CsvInput;
