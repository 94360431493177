import { createSlice } from '@reduxjs/toolkit';
import { unsignedStatementsStoreKey } from './unsignedStatements.const';
import { generateUnsignedStatements } from './unsignedStatements.thunks';

interface ISliceState {
  isLoading: boolean;
}

const initialState: ISliceState = {
  isLoading: false,
};

export const unsignedStatementsSlice = createSlice({
  name: unsignedStatementsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateUnsignedStatements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateUnsignedStatements.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(generateUnsignedStatements.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
