import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import CsvDownloadIcon from '@mui/icons-material/Archive';
import CsvErrorDownloadIcon from '@mui/icons-material/Error';
import XlsxErrorDownloadIcon from '@mui/icons-material/ContentPasteOff';
import XlsxDownloadIcon from '@mui/icons-material/Summarize';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Card,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  businessTransactionsSelectors,
  clearBusinessTransactions,
  fetchAllBusinessTransactions,
  fetchBusinessTransactionById,
} from '../../redux/businessTransactions';
import { BUSINESS_TRANSACTION_TYPES } from '../../constants';
import BusinessTransactionsTableRow from './BusinessTransactionTableRow';
import { downloadXlsxOrCsv } from './BusinessTransactions.utils';
import { IThirdPartyRequest } from '../../types';

interface IProps {
  type: BUSINESS_TRANSACTION_TYPES;
}

const BusinessTransactions: React.FC<IProps> = ({ type }) => {
  const dispatch = useAppDispatch();
  const businessTransactionsData = useAppSelector(
    businessTransactionsSelectors.getBusinessTransactionsData,
  );
  const businessTransactionsRefetch = useAppSelector(
    businessTransactionsSelectors.getBusinessTransactionsRefetch,
  );
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);

  const handleExpandRow = (id: string) => {
    setExpandedRowId((prevState) => (prevState === id ? null : id));
  };

  const fetch = () => {
    dispatch(
      fetchAllBusinessTransactions({
        type,
        offset: String(0),
        limit: String(10),
      }),
    );
  };

  useEffect(() => {
    fetch();
    return () => {
      dispatch(clearBusinessTransactions());
    };
  }, []);

  useEffect(() => {
    if (businessTransactionsRefetch) {
      fetch();
    }
  }, [businessTransactionsRefetch]);

  useEffect(() => {
    let timeoutId: number;
    if (
      !_isEmpty(businessTransactionsData) &&
      !businessTransactionsData[0].finishedAt
    ) {
      timeoutId = window.setTimeout(() => {
        dispatch(fetchBusinessTransactionById(businessTransactionsData[0].id));
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [businessTransactionsData]);

  const getDownloadActions = (reqs: IThirdPartyRequest[]) => {
    return [
      {
        label: 'XLSX Success Download',
        Icon: XlsxDownloadIcon,
        data: reqs.filter((req) => req.csvRowNumber && !req.failedReason),
        onClick: (data: IThirdPartyRequest[]) =>
          downloadXlsxOrCsv(
            data,
            'xlsx',
            'processedList.xlsx',
            new Set(['csvRowNumber', 'fullName']),
          ),
      },
      {
        label: 'CSV Success Download',
        Icon: CsvDownloadIcon,
        data: reqs.filter((req) => req.csvRowNumber && !req.failedReason),
        onClick: (data: IThirdPartyRequest[]) =>
          downloadXlsxOrCsv(
            data,
            'csv',
            'processedList.csv',
            new Set(['csvRowNumber', 'fullName']),
          ),
      },
      {
        label: 'XLSX Errors Download',
        Icon: XlsxErrorDownloadIcon,
        data: reqs.filter((req) => req.csvRowNumber && req.failedReason),
        onClick: (data: IThirdPartyRequest[]) =>
          downloadXlsxOrCsv(
            data,
            'xlsx',
            'errorsList.xlsx',
            new Set(['csvRowNumber', 'fullName', 'failedReason']),
          ),
      },
      {
        label: 'CSV Errors Download',
        Icon: CsvErrorDownloadIcon,
        data: reqs.filter((req) => req.csvRowNumber && req.failedReason),
        onClick: (data: IThirdPartyRequest[]) =>
          downloadXlsxOrCsv(
            data,
            'csv',
            'errorsList.csv',
            new Set(['csvRowNumber', 'fullName', 'failedReason']),
          ),
      },
    ];
  };

  return (
    <Card sx={{ my: 3 }}>
      <Typography variant="h6" fontWeight={400} m={2}>
        Transactions
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Start date</TableCell>
              <TableCell>End date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_isEmpty(businessTransactionsData) ? (
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell colSpan={5}>No transactions available</TableCell>
              </TableRow>
            ) : (
              businessTransactionsData.map((bt) => (
                <BusinessTransactionsTableRow
                  key={bt.id}
                  row={bt}
                  expandedRowId={expandedRowId}
                  handleExpandRow={handleExpandRow}
                  actions={
                    bt.type ===
                      BUSINESS_TRANSACTION_TYPES.UploadAccountingAutomatorDocument &&
                    bt.reqs &&
                    bt.countAll === bt.countFailed + bt.countSuccessful
                      ? getDownloadActions(bt.reqs)
                      : undefined
                  }
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default BusinessTransactions;
