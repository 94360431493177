import React from 'react';
import Drawer from '@mui/material/Drawer';
import { drawerWidth } from '../../theme';
import Navigation from '../navigation/Navigation';
import UserInfo from './components/UserInfo';
import { Typography } from '@mui/material';

const Sidebar: React.FC = () => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          py: 3,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography variant="h5" fontWeight="bold" px={2} mb={2}>
        PUMB Automator
      </Typography>
      <Navigation />
      <UserInfo />
    </Drawer>
  );
};

export default Sidebar;
