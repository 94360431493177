import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux';
import { fetchUser, userSelectors } from './redux/user';
import { InterceptorsService } from './services';
import { API_STATUS } from './constants';
import { useNotifier } from './hooks';
import PageWrapper from './components/pageWrapper/PageWrapper';
import Routes from './routes/Routes';

function App() {
  useNotifier();
  const dispatch = useAppDispatch();
  const userApiStatus = useAppSelector(userSelectors.getUserApiStatus);
  const userData = useAppSelector(userSelectors.getUserData);
  const isLoading = userApiStatus === API_STATUS.LOADING || userData === null;

  useEffect(() => {
    InterceptorsService.init();
    dispatch(fetchUser());
  }, []);

  return (
    <PageWrapper isLoading={isLoading}>
      <Routes />
    </PageWrapper>
  );
}

export default App;
