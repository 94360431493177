import React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { fadeInDuration } from '../../theme';
import Loader from '../loader/Loader';

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

const PageWrapper: React.FC<IProps> = ({ isLoading = false, children }) => {
  return (
    <>
      {isLoading && <Loader loaderState={isLoading} />}
      <Fade in={!isLoading} timeout={fadeInDuration} mountOnEnter>
        <Box>{children}</Box>
      </Fade>
    </>
  );
};

export default PageWrapper;
