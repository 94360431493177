import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from '../pages/notFound/NotFound';
import Main from '../pages/main/Main';
import { routesConfig } from './routesConfig';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';

const CustomRoutes: React.FC = () => {
  const userData = useAppSelector(userSelectors.getUserData);

  const renderRoutes = () => {
    if (!userData) {
      return;
    }

    return routesConfig.map((configElement) => {
      const { route, Element, allowedWhen } = configElement;

      if (
        allowedWhen.some((feature) =>
          userData.allowedFeatures.includes(feature),
        )
      ) {
        return <Route key={route} path={route} element={<Element />} />;
      }

      return null;
    });
  };

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        {renderRoutes()}
        <Route
          index
          element={<Navigate to="accounting-automator" replace={true} />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default CustomRoutes;
