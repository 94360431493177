import { createSlice } from '@reduxjs/toolkit';
import { currencyUploadStoreKey } from './currencyUpload.const';
import { uploadCurrency } from './currencyUpload.thunks';

interface ISliceState {
  isLoading: boolean;
}

const initialState: ISliceState = {
  isLoading: false,
};

export const currencyUploadSlice = createSlice({
  name: currencyUploadStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadCurrency.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadCurrency.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
