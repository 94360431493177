export const defaultCsvFieldMap = {
  okpo: 'OKPO',
  fullName: 'NAME En',
  docType: 'Document Type',
  year: 'Year',
  month: 'Month',
  customCriteria1: 'Criteria 1',
  customCriteria2: 'Criteria 2',
  customCriteria3: 'Criteria 3',
  customCriteria4: 'Criteria 4',
};
