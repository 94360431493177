import { createSlice } from '@reduxjs/toolkit';
import { signedStatementsStoreKey } from './signedStatements.const';
import { generateSignedStatements } from './signedStatements.thunks';

interface ISliceState {
  isLoading: boolean;
}

const initialState: ISliceState = {
  isLoading: false,
};

export const signedStatementsSlice = createSlice({
  name: signedStatementsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateSignedStatements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateSignedStatements.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(generateSignedStatements.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
