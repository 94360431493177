import React, { useState } from 'react';
import formatDate from 'date-fns/format';
import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { BUSINESS_TRANSACTION_TYPES } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux';
import { refetchBusinessTransactions } from '../../redux/businessTransactions';
import { generateSignedStatements } from '../../redux/signedStatements';
import BusinessTransactions from '../businessTransactions/BusinessTransactions';
import PageWrapper from '../../components/pageWrapper/PageWrapper';

const UnsignedStatements: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.signedStatements.isLoading);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const isGenerateDisabled = !startDate || !endDate;

  const handleGenerateSignedStatements = async () => {
    const body = {
      startDate: formatDate(startDate as Date, 'dd.MM.yyyy'),
      endDate: formatDate(endDate as Date, 'dd.MM.yyyy'),
    };
    await dispatch(generateSignedStatements(body));
    await dispatch(refetchBusinessTransactions());
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <PageWrapper>
      <Typography variant="h5" gutterBottom>
        Generate signed statements
      </Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={2}>
            <DatePicker
              label="From"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="To"
              value={endDate}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <LoadingButton
              variant="contained"
              disabled={isGenerateDisabled}
              loading={isLoading}
              onClick={handleGenerateSignedStatements}
            >
              Generate
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
      <BusinessTransactions
        type={BUSINESS_TRANSACTION_TYPES.CreateSignedFinStatement}
      />
    </PageWrapper>
  );
};

export default UnsignedStatements;
