import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import {
  apiEndpoints,
  API_BASE_URL,
  SERVER_STATUS_CODES,
  TOKEN_KEYS,
} from '../constants';
import { getApiHost } from '../utils';
import { AuthService } from './authService';
import { LocalStorageService } from './localStorageService';

interface IRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export class InterceptorsService {
  public static init(): void {
    Axios.defaults.baseURL = `${getApiHost()}${API_BASE_URL}`;

    InterceptorsService.addRequestInterceptor();
    InterceptorsService.addResponseInterceptor();
  }

  private static addRequestInterceptor(): void {
    Axios.interceptors.request.use((config) => {
      const authToken = AuthService.getToken();

      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${authToken ?? ''}`,
        },
      };

      return newConfig;
    });
  }

  private static addResponseInterceptor(): void {
    Axios.interceptors.response.use(
      async (response) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config as IRequestConfig;
        if (error?.response?.status === SERVER_STATUS_CODES.UNAUTHORIZED) {
          const refreshToken = LocalStorageService.getState(
            TOKEN_KEYS.REFRESH_TOKEN_KEY,
          );
          if (refreshToken && !originalRequest?._retry) {
            try {
              originalRequest._retry = true;
              const response = await Axios.post(apiEndpoints.authRefresh(), {
                refreshToken,
              });
              LocalStorageService.setState(
                TOKEN_KEYS.AUTH_TOKEN_KEY,
                response.data?.token,
              );
              return Axios(originalRequest);
            } catch (error) {
              AuthService.clearToken();
            }
          }
        }

        return Promise.reject(error);
      },
    );
  }
}
