const pumbApiKey = '/pumb';

export const uploadPumbCreds = () => `${pumbApiKey}/uploadPumbCreds`;

export const payTaxes = () => `${pumbApiKey}/payTaxes`;

export const sellUSD = () => `${pumbApiKey}/sellUSD`;

export const generateUnsignedFinStatement = () =>
  `${pumbApiKey}/generateUnsignedFinStatement`;

export const generateSignedFinStatement = () =>
  `${pumbApiKey}/generateSignedFinStatement`;
