import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { userStoreKey } from './user.const';

export const fetchUser = createAsyncThunk(
  `${userStoreKey}/fetchUser`,
  async () => {
    try {
      const response = await axios.get(apiEndpoints.userProfile());
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
