import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  businessTransactionsStoreKey,
  businessTransactionsSlice,
} from './businessTransactions';
import { currencyUploadStoreKey, currencyUploadSlice } from './currencyUpload';
import { notificationsStoreKey, notificationsSlice } from './notifications';
import {
  signedStatementsStoreKey,
  signedStatementsSlice,
} from './signedStatements';
import { taxUploadStoreKey, taxUploadSlice } from './taxUpload';
import {
  unsignedStatementsStoreKey,
  unsignedStatementsSlice,
} from './unsignedStatements';
import { userStoreKey, userSlice } from './user';
import { usersUploadStoreKey, usersUploadSlice } from './usersUpload';
import { automatorKey, automatorSlice } from './automator';

export const store = configureStore({
  reducer: {
    [businessTransactionsStoreKey]: businessTransactionsSlice.reducer,
    [currencyUploadStoreKey]: currencyUploadSlice.reducer,
    [notificationsStoreKey]: notificationsSlice.reducer,
    [signedStatementsStoreKey]: signedStatementsSlice.reducer,
    [taxUploadStoreKey]: taxUploadSlice.reducer,
    [unsignedStatementsStoreKey]: unsignedStatementsSlice.reducer,
    [automatorKey]: automatorSlice.reducer,
    [userStoreKey]: userSlice.reducer,
    [usersUploadStoreKey]: usersUploadSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
