export enum BUSINESS_TRANSACTION_TYPES {
  CreateCurrencySellOrder = 'CreateCurrencySellOrder',
  CreatePaymentOrder = 'CreatePaymentOrder',
  CreateUnsignedFinStatement = 'GenerateUnsignedFinStatement',
  CreateSignedFinStatement = 'GenerateSignedFinStatement',
  UploadAccountingAutomatorDocument = 'UploadAccountingAutomatorDocument',
}

export enum THIRD_PARTY_REQUEST_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED_SUCCESS = 'FINISHED_SUCCESS',
  FINISHED_FAILED = 'FINISHED_FAILED',
}
