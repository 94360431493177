import React, { useMemo } from 'react';
import _lowerCase from 'lodash/lowerCase';
import _upperFirst from 'lodash/upperFirst';
import formatDate from 'date-fns/format';
import {
  Collapse,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IBusinessTransaction } from '../../types';
import ProgressLoader from '../../components/progressLoader/ProgressLoader';
import BusinessTransactionsRequests from './BusinessTransactionRequests';
import { calculateProgressPercentage } from './BusinessTransactions.utils';

interface IAction {
  label: string;
  Icon: React.ElementType;
  data: any[];
  onClick: (data: any[]) => void;
}

interface IProps {
  row: IBusinessTransaction;
  expandedRowId: string | null;
  handleExpandRow: (id: string) => void;
  actions?: IAction[];
}

const BusinessTransactionsTableRow: React.FC<IProps> = ({
  row,
  expandedRowId,
  handleExpandRow,
  actions,
}) => {
  const isOpen = expandedRowId === row.id;

  const progressPercentage = calculateProgressPercentage(
    row.countSuccessful,
    row.countAll,
  );

  const progress = useMemo(() => {
    if (row.countFailed > 0) {
      return 'Failed';
    }
    if (
      (progressPercentage === 0 && row.finishedAt) ||
      progressPercentage === 100
    ) {
      return 'Finished';
    }
    return <ProgressLoader value={progressPercentage} />;
  }, [progressPercentage, row.countFailed, row.finishedAt]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => handleExpandRow(row.id)} size="small">
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {formatDate(new Date(row.createdAt), 'dd/MM/yyyy HH:mm')}
        </TableCell>
        <TableCell>
          {row.finishedAt
            ? formatDate(new Date(row.createdAt), 'dd/MM/yyyy HH:mm')
            : '-'}
        </TableCell>
        <TableCell>{progress}</TableCell>
        <TableCell>{_upperFirst(_lowerCase(row.type))}</TableCell>
        {actions && isOpen && (
          <TableCell>
            {actions.map((action, index) => (
              <Tooltip key={action.label} title={action.label}>
                <IconButton
                  key={index}
                  onClick={() => action.onClick(action.data)}
                >
                  <action.Icon />
                </IconButton>
              </Tooltip>
            ))}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} mountOnEnter unmountOnExit>
            <BusinessTransactionsRequests id={row.id} reqs={row.reqs} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(BusinessTransactionsTableRow);
