import { LocalStorageService } from './localStorageService';
import { CookieService } from './cookies';
import { apiEndpoints, TOKEN_KEYS, API_BASE_URL } from '../constants';
import { ICookieAuthModel } from '../types';
import { is, parseLocation, getApiHost } from '../utils';

export class AuthService {
  static getToken(): string | null {
    const token = LocalStorageService.getState<string>(
      TOKEN_KEYS.AUTH_TOKEN_KEY,
    );

    if (token) {
      return token;
    }

    const accessCookieValue =
      CookieService.decodeJsonCookieValue<ICookieAuthModel>(
        TOKEN_KEYS.AUTH_TOKEN_KEY,
      );
    const refreshCookieValue =
      CookieService.decodeJsonCookieValue<ICookieAuthModel>(
        TOKEN_KEYS.REFRESH_TOKEN_KEY,
      );

    if (refreshCookieValue && refreshCookieValue.token) {
      LocalStorageService.setState(
        TOKEN_KEYS.REFRESH_TOKEN_KEY,
        refreshCookieValue.token,
      );
      CookieService.deleteCookie(TOKEN_KEYS.REFRESH_TOKEN_KEY);
    }

    if (accessCookieValue && accessCookieValue.token) {
      LocalStorageService.setState(
        TOKEN_KEYS.AUTH_TOKEN_KEY,
        accessCookieValue.token,
      );
      CookieService.deleteCookie(TOKEN_KEYS.AUTH_TOKEN_KEY);
      return accessCookieValue.token;
    }

    AuthService.redirectToAuth();

    return null;
  }

  static clearToken(): void {
    LocalStorageService.clearState();
    AuthService.redirectToAuth();
  }

  static redirectToAuth(): void {
    const { customEmail } = parseLocation<{ customEmail?: string }>();

    if (is.localhost && !customEmail) {
      return;
    }

    const redirectUrl = customEmail
      ? apiEndpoints.authCustomLogin(customEmail)
      : apiEndpoints.authLogin();

    window.location.href = `${getApiHost()}${API_BASE_URL}${redirectUrl}`;
  }
}
