import Joi from 'joi';

export enum PurposeType {
  STRUCTURED_TAXES = 'STRUCTURED_TAXES',
  NOT_STRUCTURED = 'NOT_STRUCTURED',
  EMPTY = '',
}

export const taxPaymentSchema = Joi.object({
  A: Joi.string().required(),
  ACCOUNT_A: Joi.string().required(),
  ACCOUNT_B: Joi.string().required(),
  AMOUNT: Joi.string().required(),
  A_COUNTRY: Joi.string().required(),
  A_PASSPORTDATA: Joi.string().allow(''),
  B: Joi.string().required(),
  BANK_A: Joi.string().required(),
  BANK_B: Joi.string().required(),
  B_COUNTRY: Joi.string().required(),
  B_PASSPORTDATA: Joi.string().allow(''),
  CCY_DATE: Joi.string().allow(''),
  CITY_A: Joi.string().required(),
  CITY_B: Joi.string().required(),
  DAY: Joi.string().required(),
  DETAILS_T: Joi.string().allow(''),
  EXPENSE_ITEMS: Joi.string().allow(''),
  FEXT1: Joi.boolean().required(),
  GUILTY: Joi.string().allow(''),
  MFO_A: Joi.string().required(),
  MFO_B: Joi.string().required(),
  NUMBER: Joi.string().required(),
  OKPO_A: Joi.string().required(),
  OKPO_B: Joi.string().required(),
  PURPOSE_TYPE: Joi.string()
    .required()
    .valid(...Object.values(PurposeType)),
  DETAILS: Joi.when('PURPOSE_TYPE', {
    is: PurposeType.STRUCTURED_TAXES,
    then: Joi.string().optional().valid(''),
    otherwise: Joi.string().required(),
  }),
  BUDGET_ADDITIONAL_INFO: Joi.when('PURPOSE_TYPE', {
    is: PurposeType.STRUCTURED_TAXES,
    then: Joi.string().required(),
    otherwise: Joi.string().optional().valid(''),
  }),
  BUDGET_CODE_CERT_ID: Joi.when('PURPOSE_TYPE', {
    is: PurposeType.STRUCTURED_TAXES,
    then: Joi.string()
      .required()
      .valid(
        '058',
        '101',
        '117',
        '121',
        '125',
        '128',
        '130',
        '131',
        '136',
        '140',
        '145',
        '200',
        '278',
        '350',
        '354',
        '355',
        '356',
        '357',
        '358',
      ),
    otherwise: Joi.string().optional().valid(''),
  }),
});
