import { createTheme } from '@mui/material/styles';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';
import './global-styles.css';

export * from './variables';

export const muiTheme = createTheme({
  palette,
  breakpoints,
  typography,
  spacing: (factor: number) => factor * 8,
  components: overrides,
});
