import React from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { USERS_UPLOAD_HELP_DATA } from '../../constants';
import { parseCsvToJson, formatValidationErrorMessage } from '../../utils';
import { validateUsers } from '../../validations';
import { useAppDispatch, useAppSelector } from '../../redux';
import { notificationAlert } from '../../redux/notifications';
import { uploadUsers } from '../../redux/usersUpload';
import CsvInput from '../../components/csvInput/CsvInput';
import HelpTable from '../../components/helpTable/HelpTable';
import PageWrapper from '../../components/pageWrapper/PageWrapper';

const UsersUpload: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.usersUpload.isLoading);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [csvFile, setCsvFile] = React.useState<File | null>(null);

  const handleCsvChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files?.length) {
      const file = e.target.files?.[0] || null;
      setCsvFile(file);
    } else {
      setCsvFile(null);
    }
  };

  const handleClearCvsFile = () => {
    setCsvFile(null);
    (fileInputRef.current as HTMLInputElement).files = null;
    (fileInputRef.current as HTMLInputElement).value = '';
  };

  const handleSubmit = async () => {
    const parsedCsv = await parseCsvToJson(csvFile!);
    const validatedData = await validateUsers(parsedCsv);
    if (validatedData.error) {
      dispatch(
        notificationAlert(formatValidationErrorMessage(validatedData.error), {
          variant: 'error',
          persist: true,
        }),
      );
    } else {
      const body = validatedData.value;
      await dispatch(uploadUsers(body));
      handleClearCvsFile();
    }
  };

  return (
    <PageWrapper>
      <Typography variant="h5" gutterBottom>
        Upload user documents
      </Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack spacing={2} direction="row" alignItems="center">
            <CsvInput onChange={handleCsvChange} ref={fileInputRef} />
            <LoadingButton
              variant="contained"
              size="medium"
              loading={isLoading}
              disabled={!Boolean(csvFile)}
              onClick={handleSubmit}
            >
              Upload
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
      <HelpTable helpData={USERS_UPLOAD_HELP_DATA} />
    </PageWrapper>
  );
};

export default UsersUpload;
