import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IHelpData } from '../../types';

interface IProps {
  helpData: IHelpData[];
}

const HelpTable: React.FC<IProps> = ({ helpData }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="help-content"
        id="help"
      >
        <Typography variant="h6" fontWeight={400}>
          Help
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CSV column</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Example</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {helpData.map((help) => (
              <TableRow key={help.name}>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {help.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    {help.required ? 'Required' : 'Optional'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{help.description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{help.example}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default HelpTable;
