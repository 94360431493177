import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { automatorKey } from './automator.const';
import { apiEndpoints } from '../../constants';
import { notificationAlert } from '../notifications';
import { formatApiErrorMessage } from '../../utils';
export const upload = createAsyncThunk(
  `${automatorKey}/upload`,
  async (body: any, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.sortDocuments(), body);

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatApiErrorMessage(err), {
          variant: 'error',
          persist: true,
        }),
      );
      throw new Error(err);
    }
  },
);

export const getFolderByLink = createAsyncThunk(
  automatorKey,
  async (googleDriveFolderLink: string, thunkAPI) => {
    try {
      const response = await axios.get(
        apiEndpoints.getFolderByLink(googleDriveFolderLink),
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatApiErrorMessage(err), {
          variant: 'error',
          persist: true,
        }),
      );
      throw new Error(err);
    }
  },
);
