import JSZip from 'jszip';

export const handleZipFolder = async (files: File[]) => {
  const zip = new JSZip();
  const rootFolder = zip.folder(files[0].webkitRelativePath!.split('/')[0]);

  for (const file of files) {
    const path = file.webkitRelativePath!.split('/');
    path.shift();
    rootFolder?.file(path.join('/'), file);
  }

  const zipBlob = await zip.generateAsync({ type: 'blob' });
  return zipBlob;
};

export const containsSequence = (arr: object[], sequence: string): boolean => {
  for (const obj of arr) {
    for (const value of Object.values(obj)) {
      if (typeof value === 'string' && value.includes(sequence)) {
        return true;
      }
    }
  }
  return false;
};
