import Joi from 'joi';
import {
  taxPaymentSchema,
  currencyPaymentSchema,
  userSchema,
} from '../schemas';

export const validateTaxPayments = async (taxPayments: any[]) => {
  const schema = Joi.array().items(taxPaymentSchema);
  const validatedData = await schema.validate(taxPayments, {
    abortEarly: false,
  });
  return validatedData;
};

export const validateCurrencyPayments = async (currencyPayments: any[]) => {
  const schema = Joi.array().items(currencyPaymentSchema);
  const validatedData = await schema.validate(currencyPayments, {
    abortEarly: false,
  });
  return validatedData;
};

export const validateUsers = async (users: any[]) => {
  const schema = Joi.array().items(userSchema);
  const validatedData = await schema.validate(users, {
    abortEarly: false,
  });
  return validatedData;
};
