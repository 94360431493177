export const CURRENCY_UPLOAD_HELP_DATA = [
  {
    name: 'DOC_DATE',
    description: 'Дата',
    example: '20.01.2022',
    required: true,
  },
  {
    name: 'NUMBER',
    description: 'Номер ЭПП',
    example: '1',
    required: true,
  },
  {
    name: 'CL_NAME',
    description: 'Имя клиента',
    example: '',
    required: true,
  },
  {
    name: 'CL_OKPO',
    description: 'Код ЕДРПОУ плательщика',
    example: '999999999',
    required: true,
  },
  {
    name: 'CCY_MFO',
    description:
      'МФО банка, на который клиент переведет деньги для покупки валюты-отправителя',
    example: '999999',
    required: true,
  },
  {
    name: 'CCY_ACC',
    description: 'IBAN отправителя',
    example: 'UA999999999999999999999999999',
    required: true,
  },
  {
    name: 'CCY_BANK',
    description: 'Название банка-отправителя',
    example: 'АТ ПУМБ',
    required: true,
  },
  {
    name: 'CCY',
    description: 'Код валюты',
    example: 'USD',
    required: true,
  },
  {
    name: 'CL_ADDR',
    description: 'Адрес клиента',
    example: '61166, Харків, вулиця Коломенська, дом № 63/ЛІТ',
    required: true,
  },
  {
    name: 'CL_PHONE',
    description: 'Телефон клиента',
    example: '380509999999',
    required: true,
  },
  {
    name: 'CL_FAX',
    description: 'Факс клиента',
    example: '380509999999',
    required: true,
  },
  {
    name: 'RESP',
    description: 'Имя ответственного',
    example: 'ТОВ Еджайленжін Україна',
    required: true,
  },
  {
    name: 'RESP_PHONE',
    description: 'Телефон ответственного',
    example: '380509999999',
    required: true,
  },
  {
    name: 'AMOUNT',
    description: 'Сумма покупки валюты',
    example: '100',
    required: true,
  },
  {
    name: 'COURSE',
    description: 'Курс продажи',
    example: '30',
    required: false,
  },
  {
    name: 'UAH_MFO',
    description:
      'МФО банка, на который клиент переведет деньги для покупки валюты-получателя',
    example: '999999',
    required: true,
  },
  {
    name: 'UAH_ACC',
    description: 'IBAN получателя',
    example: 'UA999999999999999999999999999',
    required: true,
  },
  {
    name: 'UAH_BANK',
    description: 'Название банка, где находится счет для перечисления',
    example: 'АТ ПУМБ',
    required: true,
  },
  {
    name: 'UAH_OKPO',
    description: 'ЕДРПОУ получателя',
    example: '9999999999',
    required: true,
  },
  {
    name: 'CREDCHECK',
    description: 'Флаг кредита',
    example: 'TRUE / FALSE',
    required: true,
  },
  {
    name: 'CREDINFO',
    description: 'Информация по кредиту',
    example: '',
    required: true,
  },
  {
    name: 'FEXT1',
    description: 'Послеоперационное время, флаг',
    example: 'TRUE / FALSE',
    required: true,
  },
  {
    name: 'LAST_DATE',
    description: 'Действителен до',
    example: '20.01.2022',
    required: true,
  },
  {
    name: 'DOC_TYPE9',
    description: 'Нерезидент',
    example: 'TRUE / FALSE',
    required: true,
  },
];
