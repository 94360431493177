export const TAX_UPLOAD_HELP_DATA = [
  {
    name: 'DAY',
    description: 'Дата документа',
    example: '01.01.2022',
    required: true,
  },
  {
    name: 'NUMBER',
    description: 'Номер документа',
    example: '1',
    required: true,
  },
  {
    name: 'A',
    description: 'Наименование плательщика',
    example: 'Корпоративные клиенты',
    required: true,
  },
  {
    name: 'B',
    description: 'Наименование получателя',
    example: 'Тестовый клиент',
    required: true,
  },
  {
    name: 'OKPO_A',
    description: 'Код плательщика',
    example: '999999999',
    required: true,
  },
  {
    name: 'OKPO_B',
    description: 'Код получателя',
    example: '9999999999',
    required: true,
  },
  {
    name: 'ACCOUNT_A',
    description: 'Номер счета плательщика',
    example: 'UA999999999999999999999999999',
    required: true,
  },
  {
    name: 'ACCOUNT_B',
    description: 'Номер счета получателя',
    example: 'UA999999999999999999999999999',
    required: true,
  },
  {
    name: 'BANK_A',
    description: 'Наименование банка плательщика',
    example: 'ПАT "ПУМБ"',
    required: true,
  },
  {
    name: 'BANK_B',
    description: 'Наименование банка получателя',
    example: 'ПАТ "Банк "Київська Русь" м. Київ',
    required: true,
  },
  {
    name: 'MFO_A',
    description: 'Код МФО банка плательщика',
    example: '999999',
    required: true,
  },
  {
    name: 'MFO_B',
    description: 'Код МФО банка получателя',
    example: '999999',
    required: true,
  },
  {
    name: 'CITY_A',
    description: 'Город банка плательщика',
    example: 'м. Київ',
    required: true,
  },
  {
    name: 'CITY_B',
    description: 'Город банка получателя',
    example: 'м. Київ',
    required: true,
  },
  {
    name: 'AMOUNT',
    description: 'Сумма платежа',
    example: '102.00',
    required: true,
  },
  {
    name: 'DETAILS',
    description: 'Назначение платежа',
    example: 'Тестовый платеж, в т. ч. НДС 20% = 17,00 грн.',
    required: true,
  },
  {
    name: 'GUILTY',
    description: 'Ответственный',
    example: '',
    required: false,
  },
  {
    name: 'DETAILS_T',
    description: 'Дополнительные характеристики',
    example: '',
    required: false,
  },
  {
    name: 'A_PASSPORTDATA',
    description: 'Номер и серия паспорта плательщика',
    example: '',
    required: false,
  },
  {
    name: 'B_PASSPORTDATA',
    description: 'Номер и серия паспорта получателя',
    example: '',
    required: false,
  },
  {
    name: 'EXPENSE_ITEMS',
    description: '-',
    example: '',
    required: false,
  },
  {
    name: 'FEXT1',
    description: 'Послеоперационное время, флаг',
    example: 'TRUE / FALSE',
    required: true,
  },
  {
    name: 'CCY_DATE',
    description: 'Дата обработки',
    example: '01.01.2022',
    required: false,
  },
  {
    name: 'A_COUNTRY',
    description: 'Код страны платильщика',
    example: '804',
    required: true,
  },
  {
    name: 'B_COUNTRY',
    description: 'Код страны получателя',
    example: '804',
    required: true,
  },
  {
    name: 'PURPOSE_TYPE',
    description: 'Тип назначения платежа',
    example: 'STRUCTURED_TAXES или NON_STRUCTURED или пустая строка',
    required: false,
  },
  {
    name: 'BUDGET_CODE_CERT_ID',
    description: 'Код вида платежа',
    example: '101, 117, 121 и так далее',
    required: false,
  },
  {
    name: 'BUDGET_ADDITIONAL_INFO',
    description: 'Тип назначения платежа',
    example: 'Сплата податку за Червень 2023',
    required: false,
  },
];
