const authApiKey = '/auth';

export const authLogin = () => `${authApiKey}/login`;

export const authCustomLogin = (email: string) =>
  `${authApiKey}/custom-login?email=${email}`;

export const authRefresh = () => `${authApiKey}/refresh`;

export const userProfile = () => `${authApiKey}/profile`;
