import Joi from 'joi';

export const currencyPaymentSchema = Joi.object({
  DOC_DATE: Joi.string().required(),
  NUMBER: Joi.string().required(),
  RESP: Joi.string().required(),
  RESP_PHONE: Joi.string().required(),
  AMOUNT: Joi.string().required(),
  COURSE: Joi.string().empty('').default(null),
  CCY: Joi.string().required(),
  CL_NAME: Joi.string().required(),
  CL_OKPO: Joi.string().required(),
  CL_ADDR: Joi.string().required(),
  CL_PHONE: Joi.string().required(),
  CL_FAX: Joi.string().required(),
  UAH_MFO: Joi.string().required(),
  UAH_ACC: Joi.string().required(),
  UAH_BANK: Joi.string().required(),
  CCY_MFO: Joi.string().required(),
  CCY_ACC: Joi.string().required(),
  CCY_BANK: Joi.string().required(),
  UAH_OKPO: Joi.string().required(),
  CREDCHECK: Joi.boolean().required(),
  CREDINFO: Joi.string().required(),
  FEXT1: Joi.boolean().required(),
  LAST_DATE: Joi.string().required(),
  DOC_TYPE9: Joi.boolean().required(),
});
