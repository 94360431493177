import { IPlainObject } from '../types';

export function parseLocation<T = IPlainObject>(source?: string): T {
  const { search } = location;

  return (source || search || '')
    .replace(/\??/gi, '')
    .split('&')
    .reduce<T>((acum, item) => {
      if (!item) {
        return acum;
      }

      const [key, value] = item.split('=');

      return {
        ...acum,
        [key]: value,
      };
    }, {} as T);
}
