import React, { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _lowerCase from 'lodash/lowerCase';
import _upperFirst from 'lodash/upperFirst';
import formatDate from 'date-fns/format';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  fetchBusinessTransactionById,
  businessTransactionsSelectors,
} from '../../redux/businessTransactions';
import { IThirdPartyRequest } from '../../types';
import { API_STATUS } from '../../constants';
import Loader from '../../components/loader/Loader';

interface IProps {
  id: string;
  reqs: IThirdPartyRequest[] | undefined;
}

const BusinessTransactionRequests: React.FC<IProps> = ({ id, reqs }) => {
  const dispatch = useAppDispatch();
  const transactionIdApiStatus = useAppSelector(
    businessTransactionsSelectors.getBusinessTransactionsTransactionIdApiStatus,
  );
  const isLoading = transactionIdApiStatus === API_STATUS.LOADING;
  const adjustedReqs =
    reqs && reqs[0]?.csvRowNumber ? _sortBy([...reqs], ['csvRowNumber']) : reqs;

  useEffect(() => {
    if (!reqs) {
      dispatch(fetchBusinessTransactionById(id));
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader loaderState={isLoading} />
      ) : (
        <Box my={2}>
          <Typography gutterBottom>Requests</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {reqs && reqs[0]?.pumbCreds?.fullName && (
                    <TableCell> Name</TableCell>
                  )}
                  {reqs && reqs[0]?.csvRowNumber && (
                    <TableCell>CSVRow</TableCell>
                  )}
                  {reqs && reqs[0]?.fullName && (
                    <TableCell>Full Name</TableCell>
                  )}
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_isEmpty(reqs) ? (
                  <TableRow>
                    <TableCell colSpan={4}>No requests available</TableCell>
                  </TableRow>
                ) : (
                  adjustedReqs?.map((request) => (
                    <TableRow key={request.id}>
                      {request.pumbCreds && (
                        <TableCell>{request.pumbCreds.fullName}</TableCell>
                      )}
                      {request.csvRowNumber && (
                        <TableCell>{request.csvRowNumber}</TableCell>
                      )}
                      {request.fullName && (
                        <TableCell>{request.fullName}</TableCell>
                      )}
                      <TableCell>
                        {formatDate(
                          new Date(request.updatedAt),
                          'dd/MM/yyyy HH:mm',
                        )}
                      </TableCell>
                      <TableCell>
                        {_upperFirst(_lowerCase(request.status))}
                      </TableCell>
                      <TableCell>{request.failedReason ?? ''}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default React.memo(BusinessTransactionRequests);
