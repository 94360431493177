import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
  loaderState: boolean;
  fullScreen?: boolean;
}

const Loader: React.FC<IProps> = ({ loaderState, fullScreen = false }) => (
  <Backdrop
    sx={{
      bgcolor: fullScreen ? undefined : 'transparent',
      position: fullScreen ? 'fixed' : 'absolute',
      zIndex: (theme) => theme.zIndex.drawer + 100,
    }}
    open={loaderState}
  >
    <CircularProgress />
  </Backdrop>
);

export default Loader;
