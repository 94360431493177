import { createSlice } from '@reduxjs/toolkit';
import { usersUploadStoreKey } from './usersUpload.const';
import { uploadUsers } from './usersUpload.thunks';

interface ISliceState {
  isLoading: boolean;
}

const initialState: ISliceState = {
  isLoading: false,
};

export const usersUploadSlice = createSlice({
  name: usersUploadStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadUsers.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadUsers.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
