import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatApiErrorMessage } from '../../utils';
import { apiEndpoints } from '../../constants';
import { notificationAlert } from '../notifications';
import { signedStatementsStoreKey } from './signedStatements.const';

export const generateSignedStatements = createAsyncThunk(
  `${signedStatementsStoreKey}/generateUnsignedStatements`,
  async (body: any, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.generateSignedFinStatement(),
        body,
      );
      thunkAPI.dispatch(
        notificationAlert('Generating signed statements', {
          variant: 'success',
        }),
      );
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatApiErrorMessage(err), {
          variant: 'error',
          persist: true,
        }),
      );
      throw new Error(err);
    }
  },
);
