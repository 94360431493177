import { NodeEnv, DEV_HOST, LOCALHOST, LOCALHOST_PORTS } from '../constants';
import { is } from './is';

export const getApiHost = (): string => {
  if (!is.localhost) {
    return '';
  }

  return process.env.REACT_APP_ENV === NodeEnv.Localhost
    ? `http://${LOCALHOST}:${LOCALHOST_PORTS.BACKEND}`
    : DEV_HOST;
};
