import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatApiErrorMessage } from '../../utils';
import { apiEndpoints } from '../../constants';
import { notificationAlert } from '../notifications';
import { currencyUploadStoreKey } from './currencyUpload.const';

export const uploadCurrency = createAsyncThunk(
  `${currencyUploadStoreKey}/uploadTaxes`,
  async (body: any, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.sellUSD(), body);
      thunkAPI.dispatch(
        notificationAlert('Currency documents submitted', {
          variant: 'success',
        }),
      );
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatApiErrorMessage(err), {
          variant: 'error',
          persist: true,
        }),
      );
      throw new Error(err);
    }
  },
);
