import { API_STATUS } from '../../constants';
import { IBusinessTransaction } from '../../types';
import { IBusinessTransactionsSliceState } from './businessTransactions.slice';
import { businessTransactionsStoreKey } from './businessTransactions.const';

interface IState {
  [businessTransactionsStoreKey]: IBusinessTransactionsSliceState;
}

const selectBusinessTransactionState = (state: IState) =>
  state[businessTransactionsStoreKey];

const getBusinessTransactionsApiStatus = (state: IState): API_STATUS =>
  selectBusinessTransactionState(state).apiStatus;

const getBusinessTransactionsTransactionIdApiStatus = (
  state: IState,
): API_STATUS => selectBusinessTransactionState(state).transactionIdApiStatus;

const getBusinessTransactionsData = (state: IState): IBusinessTransaction[] =>
  selectBusinessTransactionState(state).data;

const getBusinessTransactionsRefetch = (state: IState): boolean =>
  selectBusinessTransactionState(state).refetch;

export const businessTransactionsSelectors = {
  getBusinessTransactionsApiStatus,
  getBusinessTransactionsTransactionIdApiStatus,
  getBusinessTransactionsData,
  getBusinessTransactionsRefetch,
};
