import ArticleIcon from '@mui/icons-material/Article';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import UsersUpload from '../features/usersUpload/UsersUpload';
import { AllowedFeatures } from '../types';
import AccountingAutomator from '../features/accountingAutomator/AccountingAutomator';
import SignedStatements from '../features/signedStatements/SignedStatements';
import CurrencyUpload from '../features/currencyUpload/CurrencyUpload';
import UnsignedStatements from '../features/unsignedStatements/UnsignedStatements';
import TaxUpload from '../features/taxUpload/TaxUpload';

export const routesConfig = [
  {
    route: 'users-upload',
    Element: UsersUpload,
    allowedWhen: [AllowedFeatures.ALL],
    label: 'User documents',
    Icon: MailIcon,
  },
  {
    route: 'tax-upload',
    Element: TaxUpload,
    allowedWhen: [AllowedFeatures.ALL],
    label: 'Tax documents',
    Icon: InboxIcon,
  },
  {
    route: 'currency-upload',
    Element: CurrencyUpload,
    allowedWhen: [AllowedFeatures.ALL],
    label: 'Currency documents',
    Icon: AttachMoneyIcon,
  },
  {
    route: 'unsigned-statements',
    Element: UnsignedStatements,
    allowedWhen: [AllowedFeatures.ALL],
    label: 'Unsigned Statements',
    Icon: ArticleIcon,
  },
  {
    route: 'signed-statements',
    Element: SignedStatements,
    allowedWhen: [AllowedFeatures.ALL],
    label: 'Signed Statements',
    Icon: ArticleIcon,
  },
  {
    route: 'accounting-automator',
    Element: AccountingAutomator,
    allowedWhen: [AllowedFeatures.ALL, AllowedFeatures.ACCOUNTING_AUTOMATOR],
    label: 'Accounting Automator',
    Icon: ArticleIcon,
  },
];
