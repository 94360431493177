import Joi from 'joi';
import { ERROR_MESSAGES } from '../constants';

export const formatValidationErrorMessage = (
  error: Joi.ValidationError,
): string => {
  const errorMessage = error.details.reduce((acc, errObj) => {
    const messageWithoutPath = errObj.message.slice(
      errObj.message.indexOf(' '),
    );
    acc += `Row ${Number(errObj.path[0]) + 1} ${
      errObj.path[1]
    }${messageWithoutPath}.\n`;
    return acc;
  }, 'Please check CSV document for errors. \n');
  return errorMessage;
};

const formatApiValidationErrorMessage = (messageArr: string[]): string => {
  return messageArr.reduce((acc: string, message: string) => {
    let error = '';
    const path = message.split(' ')[0].split('.');
    const errorMessage = message.slice(message.indexOf(' ') + 1);
    for (const pathElem of path) {
      const numb = Number(pathElem);
      if (Number.isInteger(numb)) {
        error += `element #${numb + 1}`;
      } else {
        error += ERROR_MESSAGES[pathElem] || pathElem;
      }
      error += ' > ';
    }
    error += errorMessage;
    acc += `${error}\n`;
    return acc;
  }, '');
};

export const formatApiErrorMessage = (error: any): string => {
  let errorMessage = 'Something went wrong \n';

  if (error?.response?.data?.data?.length > 0) {
    errorMessage = error.response.data.data.reduce(
      (acc: string, errObj: { description: string }) => {
        acc += `${errObj.description}\n`;
        return acc;
      },
      '',
    );
  } else if (error?.response?.data?.description) {
    errorMessage = error.response.data.description;
  } else if (
    error?.response?.data?.message &&
    Array.isArray(error?.response?.data?.message)
  ) {
    errorMessage = formatApiValidationErrorMessage(error.response.data.message);
  } else if (error?.response?.data?.message) {
    errorMessage = error.response.data.message;
  } else if (error?.message) {
    errorMessage = error.message;
  }

  return errorMessage;
};
