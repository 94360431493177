import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Sidebar from '../../features/sidebar/Sidebar';

const Main: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container
        component="main"
        maxWidth="xl"
        sx={{
          bgcolor: 'background.default',
          minHeight: '100vh',
          position: 'relative',
          py: 3,
        }}
      >
        <Outlet />
      </Container>
    </Box>
  );
};

export default Main;
