import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccountingAutomatorHelp: React.FC = () => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="help-content"
        id="help"
      >
        <Typography variant="h6" fontWeight={400}>
          Help
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {' '}
        <Typography component="div" variant="body1">
          <ol>
            <li>
              Make sure to share the folder for editing with the service account
              email, so the application will have access to it:{' '}
              <code>pumb-automator@pumb-automator.iam.gserviceaccount.com</code>{' '}
              (for production).
            </li>
            <li>
              Attach a register file based on the Register Template with the
              following headers:
              <ul>
                <li>NAME En</li>
                <li>Document Type</li>
                <li>Year</li>
                <li>Month</li>
                <li>Criteria 1</li>
                <li>Criteria 2</li>
                <li>Criteria 3</li>
                <li>Criteria 4</li>
              </ul>
              Also, please note that data is parsed starting from the 3rd row
              (1st - headers, 2nd - description, 3rd and further - data). In
              case you receive an error about invalid headers, check the case
              and spelling of headers in the CSV file.
            </li>
            <li>
              Choose a ZIP archive or folder (which will be zipped on the fly)
              with the files you want to upload.
            </li>
            <li>
              Create the rename template using helper chips or type it by hand.
              For example
              <blockquote>
                For example: AE-{`{NAME En}`}-{`{OKPO}`}-{`{Document Type}`}-
                {`{Year}`}-{`{Month}`}-{`{Criteria 2}`}
              </blockquote>
              Everything inside <code>{`{}`}</code> braces will be parsed and
              matched with the data in the Register. Also, please note that
              empty fields in the register file (e.g., the column with the
              header "Criteria 4" is empty for all data rows) will not be shown
              in suggestions.
            </li>
            <li>
              Select the field from the register to search by and match the
              filename with the data row. Also, please note that empty fields in
              the register file (e.g., the column with the header "Criteria 4"
              is empty for all data rows) will not be shown as options for
              selections.
            </li>
            <li>
              Provide the Google Drive folder link that will be the root folder
              for storing all the files in the following format:{' '}
              <blockquote>
                https://drive.google.com/drive/u/0/folders/123
              </blockquote>
              It will check in real-time if this folder exists and if the
              application has access to it. The application also expects the
              folder with the name "unsorted" in this root folder, which will be
              used for storing files if any errors occur.
            </li>
            <li>
              Choose level 1 - level 4 custom actions.
              <ul>
                <li>"Empty" option disables passing the parameter.</li>
                <li>
                  "Find existing" - the selected field will be used to find an
                  existing folder with the corresponding name.
                </li>
                <li>
                  "Create" - the selected field will be used to create a new
                  folder with the corresponding name.
                </li>
              </ul>
              Also, please note that empty fields in the register file (e.g.,
              the column with the header "Criteria 4" is empty for all data
              rows) will not be shown in suggestions.
            </li>
            <li>
              Click "Upload." Application will do all the needed validations and
              after it creates a new record in the "Business Transactions" where
              you can track progress. After the finishing you can download the
              resulted data in csv/xlsx formats
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccountingAutomatorHelp;
