import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { automatorKey } from './automator.const';
import { upload, getFolderByLink } from './automator.thunks';

interface IFolderDescr {
  id: string;
  name: string;
}

interface ISliceState {
  isLoading: boolean;
  existingGoogleFolder: IFolderDescr | null;
}

const initialState: ISliceState = {
  isLoading: false,
  existingGoogleFolder: null,
};

export const automatorSlice = createSlice({
  name: automatorKey,
  initialState,
  reducers: {
    clearExistingFolderLink(state) {
      state.existingGoogleFolder = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(upload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(upload.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(upload.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getFolderByLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getFolderByLink.fulfilled,
        (state, action: PayloadAction<IFolderDescr>) => {
          state.isLoading = false;
          state.existingGoogleFolder = action.payload;
        },
      )
      .addCase(getFolderByLink.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearExistingFolderLink } = automatorSlice.actions;
